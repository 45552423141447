
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { Consignment } from '@/entities/purchase'

@Component
  export default class StockDaily extends Cell {
  stockData: { inStock: number, withoutLeads: number, average: number } | null = null

  @Watch('item', { immediate: true, deep: true })
  async onStockId (value: Consignment) {
    const stockId = value?.negotiation?.inspection?.enablement?.stock?.id
    if (stockId) {
      this.stockData = await this.getStockDaily(stockId)
    }
  }
  }
